import request from '@/api/request.js'
import * as utils from '@/utils/index'
import qs from 'qs'

//我的申请
export function getApply(params) {
  return request({
    url: `applyAndAudit/apply`,
    method: 'get',
    params,
    paramsSerializer: function (param) {
      return qs.stringify(param)
    }
  })
}

//我的审核
export function getAudit(params) {
  return request({
    url: `applyAndAudit/audit`,
    method: 'get',
    params
  })
}

//我的设备
export function getMyEquipment(params) {
  return request({
    url: `myEquipment/page`,
    method: 'get',
    params
  })
}

//确认收货
export function confirmDelivery(params, data) {
  return request({
    url: `applyAndAudit/confirmDelivery`,
    method: 'post',
    params,
    data
  })
}

//确认收货列表
export function detailList(params) {
  return request({
    url: `applyAndAudit/detailList`,
    method: 'get',
    params
  })
}

//申请信息
export function applyResult(params) {
  return request({
    url: `applyAndAudit/applyResult`,
    method: 'get',
    params
  })
}

//故障返修申请
export function faultRepairSave(data) {
  return request({
    url: `faultRepair/save`,
    method: 'post',
    data
  })
}

//故障返修编辑
export function faultRepairUpdate(data) {
  return request({
    url: `faultRepair/update`,
    method: 'post',
    data
  })
}

//故障返修详情
export function faultRepairInfo(params) {
  return request({
    url: `faultRepair/info`,
    method: 'get',
    params
  })
}
//故障返修物品清单
export function exportFaultRepairInfo(params) {
  return new Promise((resolve, reject) => {
    request
      .get('faultRepair/export', {
        params,
        responseType: 'blob'
      })
      .then(data => {
        let filename = '故障返修物品清单.xlsx'
        if (data.headers && data.headers['content-disposition']) {
          const disposition = data.headers['content-disposition']
          filename = decodeURIComponent(
            disposition.split(';')[1].split('filename=')[1]
          )
        }
        utils.downloadFileBlob(data.data, filename)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//备机申请
export function standByApplySave(data) {
  return request({
    url: `standByApply/save`,
    method: 'post',
    data
  })
}
//备机编辑
export function standByApplyUpdate(data) {
  return request({
    url: `standByApply/update`,
    method: 'post',
    data
  })
}

//备机申请详情
export function standByApplyInfo(params) {
  return request({
    url: `standByApply/info`,
    method: 'post',
    params
  })
}

//备机申请物品 清单下载
export function exportStandByApplyInfo(params) {
  return new Promise((resolve, reject) => {
    request
      .get('standByApply/export', {
        params,
        responseType: 'blob'
      })
      .then(data => {
        let filename = '备机申请物品清单.xlsx'
        if (data.headers && data.headers['content-disposition']) {
          const disposition = data.headers['content-disposition']
          filename = decodeURIComponent(
            disposition.split(';')[1].split('filename=')[1]
          )
        }
        utils.downloadFileBlob(data.data, filename)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//配件申请
export function partsApplySave(data) {
  return request({
    url: `partsApply/save`,
    method: 'post',
    data
  })
}

//配件编辑
export function partsApplyUpdate(data) {
  return request({
    url: `partsApply/update`,
    method: 'post',
    data
  })
}

//配件申请详情
export function partsApplyInfo(params) {
  return request({
    url: `partsApply/info`,
    method: 'post',
    params
  })
}

//配件申请物品清单下载
export function exportPartsApplyInfo(params) {
  return new Promise((resolve, reject) => {
    request
      .get('partsApply/export', {
        params,
        responseType: 'blob'
      })
      .then(data => {
        let filename = '配件申请物品清单.xlsx'
        if (data.headers && data.headers['content-disposition']) {
          const disposition = data.headers['content-disposition']
          filename = decodeURIComponent(
            disposition.split(';')[1].split('filename=')[1]
          )
        }
        utils.downloadFileBlob(data.data, filename)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//服务配件归还申请
export function partsReturnSave(data) {
  return request({
    url: `partsReturn/save`,
    method: 'post',
    data
  })
}

//服务配件编辑
export function partsReturnUpdate(data) {
  return request({
    url: `partsReturn/update`,
    method: 'post',
    data
  })
}

//服务配件归还申请详情
export function partsReturnInfo(params) {
  return request({
    url: 'partsReturn/info',
    method: 'post',
    params
  })
}

//服务配件归还申请物品清单下载
export function exportPartsReturnInfo(params) {
  return new Promise((resolve, reject) => {
    request
      .get('partsReturn/export', {
        params,
        responseType: 'blob'
      })
      .then(data => {
        let filename = '服务配件归还申请物品清单.xlsx'
        if (data.headers && data.headers['content-disposition']) {
          const disposition = data.headers['content-disposition']
          filename = decodeURIComponent(
            disposition.split(';')[1].split('filename=')[1]
          )
        }
        utils.downloadFileBlob(data.data, filename)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//服务收费单申请
export function sellOrderSave(data) {
  return request({
    url: `sellOrder/save`,
    method: 'post',
    data
  })
}

//服务收费单编辑
export function sellOrderUpdate(data) {
  return request({
    url: `sellOrder/update`,
    method: 'post',
    data
  })
}

//服务收费单详情
export function sellOrderInfo(params) {
  return request({
    url: 'sellOrder/info',
    method: 'post',
    params
  })
}

//服务收费单号列表
export function listSellOrderCode(params) {
  return request({
    url: 'applyAndAudit/listOrderCode',
    method: 'get',
    params
  })
}

//服务收费单详情
export function sellOrderDetail(params) {
  return request({
    url: 'sellOrder/detail',
    method: 'get',
    params
  })
}

//服务收费单物料详情
export function sellOrderDetails(params) {
  return request({
    url: 'sellOrder/details',
    method: 'get',
    params
  })
}

//服务收费单物品清单下载
export function exportSellOrderInfo(params) {
  return new Promise((resolve, reject) => {
    request
      .get('sellOrder/export', {
        params,
        responseType: 'blob'
      })
      .then(data => {
        let filename = '服务收费单申请物品清单.xlsx'
        if (data.headers && data.headers['content-disposition']) {
          const disposition = data.headers['content-disposition']
          filename = decodeURIComponent(
            disposition.split(';')[1].split('filename=')[1]
          )
        }
        utils.downloadFileBlob(data.data, filename)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//设备形态转换申请
export function convertSave(data) {
  return request({
    url: `convert/save`,
    method: 'post',
    data
  })
}

//设备形态转换编辑
export function convertUpdate(data) {
  return request({
    url: `convert/update`,
    method: 'post',
    data
  })
}

//设备形态转换申请详情
export function convertInfo(params) {
  return request({
    url: 'convert/info',
    method: 'post',
    params
  })
}

//设备形态转换申请物品清单下载
export function exportConvertInfo(params) {
  return new Promise((resolve, reject) => {
    request
      .get('convert/export', {
        params,
        responseType: 'blob'
      })
      .then(data => {
        let filename = '设备形态转换申请物品清单.xlsx'
        if (data.headers && data.headers['content-disposition']) {
          const disposition = data.headers['content-disposition']
          filename = decodeURIComponent(
            disposition.split(';')[1].split('filename=')[1]
          )
        }
        utils.downloadFileBlob(data.data, filename)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//其他入库申请
export function storageOrderSave(data) {
  return request({
    url: `storageOrder/save`,
    method: 'post',
    data
  })
}

//其他入库编辑
export function storageOrderUpdate(data) {
  return request({
    url: `storageOrder/update`,
    method: 'post',
    data
  })
}

//其他入库详情
export function storageOrderInfo(params) {
  return request({
    url: 'storageOrder/info',
    method: 'post',
    params
  })
}

//其他入库物品清单
export function exportStorageOrderInfo(params) {
  return new Promise((resolve, reject) => {
    request
      .get('storageOrder/export', {
        params,
        responseType: 'blob'
      })
      .then(data => {
        let filename = '其他入库申请物品清单.xlsx'
        if (data.headers && data.headers['content-disposition']) {
          const disposition = data.headers['content-disposition']
          filename = decodeURIComponent(
            disposition.split(';')[1].split('filename=')[1]
          )
        }
        utils.downloadFileBlob(data.data, filename)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}
//
export function exportOrderDetail(params) {
  return request({
    url: 'applyAndAudit/export',
    method: 'get',
    params
  })
}

//领料申请保存
export function pickingOrderSave(data) {
  return request({
    url: `pickingOrder/save`,
    method: 'post',
    data
  })
}

//领料申请详情
export function pickingOrderInfo(params) {
  return request({
    url: 'pickingOrder/info',
    method: 'get',
    params
  })
}

//领料申请物品清单
export function exportPickingOrderInfo(params) {
  return new Promise((resolve, reject) => {
    request
      .get('pickingOrder/export', {
        params,
        responseType: 'blob'
      })
      .then(data => {
        let filename = '领料申请物品清单.xlsx'
        if (data.headers && data.headers['content-disposition']) {
          const disposition = data.headers['content-disposition']
          filename = decodeURIComponent(
            disposition.split(';')[1].split('filename=')[1]
          )
        }
        utils.downloadFileBlob(data.data, filename)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//发票申请保存
export function invoiceOrderSave(data) {
  return request({
    url: `invoiceOrder/save`,
    method: 'post',
    data
  })
}

//发票申请详情
export function invoiceOrderInfo(params) {
  return request({
    url: 'invoiceOrder/info',
    method: 'get',
    params
  })
}

//发票申请物品清单
export function exportInvoiceOrderInfo(params) {
  return new Promise((resolve, reject) => {
    request
      .get('invoiceOrder/export', {
        params,
        responseType: 'blob'
      })
      .then(data => {
        let filename = '发票申请物品清单.xlsx'
        if (data.headers && data.headers['content-disposition']) {
          const disposition = data.headers['content-disposition']
          filename = decodeURIComponent(
            disposition.split(';')[1].split('filename=')[1]
          )
        }
        utils.downloadFileBlob(data.data, filename)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

// 服务收费单、服务配件归还申请、设备形态转换申请 审核通过前判断数量能否审核通过
export function checkNum(param) {
  return request({
    url: `/applyAndAudit/checkNum`,
    method: 'get',
    params: param
  })
}

// 领料申请，提交前校验数量
export function checkNumBeforeSubmit(data) {
  return request({
    url: `/pickingOrder/checkNum`,
    method: 'post',
    data
  })
}

// 添加备注
export function addRemark(data) {
  return request({
    url: `/applyAndAudit/addRemark`,
    method: 'post',
    data
  })
}
// 备注列表
export function listRemark(param) {
  return request({
    url: `/applyAndAudit/listRemark`,
    method: 'get',
    params: param
  })
}

// 业务查询
export function getOrder(data) {
  return request({
    url: `/applyAndAudit/order`,
    method: 'post',
    data
  })
}

// 校验是否可发起作废申请
export function checkInvalidated(param) {
  return request({
    url: `/applyAndAudit/checkInvalidated`,
    method: 'get',
    params: param
  })
}

// 校验是否可发起作废申请
export function invalidated(data) {
  return request({
    url: `/applyAndAudit/invalidated`,
    method: 'post',
    data
  })
}

// 根据SN判断是否在保
export function keepService(sn) {
  return request({
    url: `/asset/data/keepServiceBySn?sn=${sn}`,
    method: 'get',
  })
}
//
export function exportOrder(data) {
  return request({
    url: 'applyAndAudit/orderExport',
    method: 'post',
    data
  })
}