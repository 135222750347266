//审核申请详情
<template>
  <Page :showReturn="true">
    <el-button
      class="exportBtn"
      type="primary"
      size="mini"
      @click="exportPdfHtml"
      v-loading="exportLoading"
      v-if="fromPage === 'myAudit' || fromPage === 'myApply'"
    >
      导出申请单据
    </el-button>
    <AuditHeader
      slot="auditHeader"
      v-show="showReject"
      :applyResult="applyResult"
    />
    <div
      ref="pdfHtml"
      class="pdfHtml"
      :style="exportLoading ? 'width:794px' : ''"
    >
      <!-- 故障返修详情 -->
      <DetailType0
        :orderResult="orderResult"
        :detailResults="detailResults"
        @export="handleExport"
        @downloadFile="downloadFile"
        v-if="type === 0"
      />
      <!-- 备机|配件详情 -->
      <DetailType1
        :orderResult="orderResult"
        :detailResults="detailResults"
        :applyResult="applyResult"
        :type="type"
        @export="handleExport"
        @downloadFile="downloadFile"
        v-else-if="[1, 11, 2, 21].includes(type)"
      />
      <!-- 服务配件归还详情 -->
      <DetailType3
        :orderResult="orderResult"
        :detailResults="detailResults"
        @export="handleExport"
        @downloadFile="downloadFile"
        v-else-if="type === 3"
      />
      <!-- 服务收费单详情 -->
      <DetailType4
        :orderResult="orderResult"
        :detailResults="detailResults"
        @export="handleExport"
        @downloadFile="downloadFile"
        v-else-if="type === 4"
      />
      <!-- 设备形态转换详情 -->
      <DetailType5
        :orderResult="orderResult"
        :detailResults="detailResults"
        @export="handleExport"
        @downloadFile="downloadFile"
        v-else-if="type === 5"
      />
      <!-- 设备形态转换详情 -->
      <DetailType6
        :orderResult="orderResult"
        :detailResults="detailResults"
        @export="handleExport"
        @downloadFile="downloadFile"
        v-else-if="type === 6"
      />
      <!-- 领料申请详情 -->
      <DetailType7
        :type="type"
        :orderResult="orderResult"
        :detailResults="detailResults"
        :applyResult="applyResult"
        @export="handleExport"
        @downloadFile="downloadFile"
        v-else-if="[7, 71].includes(type)"
      />
      <!-- 发票申请详情 -->
      <DetailType8
        :orderResult="orderResult"
        :detailResults="detailResults"
        @export="handleExport"
        @downloadFile="downloadFile"
        v-else-if="type === 8"
      />
      <template v-if="fromPage != 'myDevice' && fromPage != 'orderQuery'">
        <div>
          <el-divider content-position="left">审批流程</el-divider>
          <div
            v-if="
              processInstanceIdInfo.nodes &&
              processInstanceIdInfo.nodes.length > 0
            "
            style="margin-top: 20px"
          >
            <el-timeline :hide-timestamp="true">
              <el-timeline-item
                v-for="(node, index) in processInstanceIdInfo.nodes"
                :key="index"
              >
                <div>
                  {{ node.title }}&emsp;
                  <el-tag
                    v-if="node.userType != 1"
                    :type="
                      ['info', 'primary', 'success', 'danger', 'danger'][
                        node.status
                      ] || 'info'
                    "
                  >
                    {{
                      node.userType == 2 || node.userType == 4
                        ? nodeStatus[node.status]
                          ? nodeStatus[node.status]
                          : ''
                        : ccStatus[node.status]
                        ? ccStatus[node.status]
                        : ''
                    }}
                  </el-tag>
                  <el-tag
                    v-if="node.userType == 1 && node.status == 4"
                    :type="
                      ['info', 'primary', 'success', 'danger', 'danger'][
                        node.status
                      ] || 'info'
                    "
                  >
                    {{ nodeStatus[node.status] ? nodeStatus[node.status] : '' }}
                  </el-tag>
                </div>
                <div v-if="node.userType == 2 || node.userType == 4">
                  <div v-for="(user, index) in node.users" :key="index">
                    <div class="row-font">
                      {{ user.nickname }}&emsp;
                      <span v-if="node.status != 0">
                        {{
                          userStatus[user.status] ? userStatus[user.status] : ''
                        }}
                      </span>
                    </div>
                    <div
                      class="row-font"
                      v-if="node.status != 0 && user.status > 1"
                    >
                      审批时间：{{ user.datetime }}
                    </div>
                    <div
                      class="row-font"
                      v-if="node.status != 0 && user.status > 1"
                    >
                      审批备注：{{ user.comments || '-' }}
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="row-font">
                    {{ getNicknames(node.users) }}
                  </div>
                  <div
                    class="row-font"
                    v-if="
                      node.status != 0 &&
                      node.status != 1 &&
                      node.users.length > 0
                    "
                  >
                    {{ userType[node.userType] }}时间：{{
                      node.users[0].datetime
                    }}
                  </div>
                </div>
              </el-timeline-item>
              <!-- 系统异常 -->
              <el-timeline-item
                v-if="
                  processInstanceIdInfo && processInstanceIdInfo.status == 10
                "
              >
                <div class="">
                  <el-tag type="danger">系统异常</el-tag>
                  &emsp;{{ processInstanceIdInfo.error || '-' }}
                </div>
              </el-timeline-item>
            </el-timeline>
          </div>
          <div>
            <el-button type="text" @click="remarkVisible = true">
              添加备注
            </el-button>
            <div style="width: 60%">
              <div class="remark" v-for="item in remarks" :key="item.id">
                <div class="title">
                  <span style="font-weight: 600">{{ item.createBy }}</span>
                  <span style="color: #cdcdcd">{{ item.createTime }}</span>
                </div>
                <div class="content">
                  <div style="margin-bottom: 5px">{{ item.remark }}</div>
                  <span
                    style="color: #00daff; margin-right: 5px"
                    v-for="user in item.noticer || []"
                  >
                    @{{ user }}
                  </span>
                  <div
                    style="margin-top: 5px"
                    v-if="item.imgUrls && item.imgUrls.length > 0"
                  >
                    <el-image
                      class="img"
                      v-for="url in item.imgUrls"
                      :src="url"
                      fit="scale-down"
                      :preview-src-list="item.imgUrls"
                    ></el-image>
                  </div>
                  <div
                    style="margin-top: 5px"
                    v-if="item.pdfs && item.pdfs.length > 0"
                  >
                    <el-image
                      class="img"
                      v-for="url in item.pdfs"
                      :src="require(`../../assets/image/pdf.png`)"
                      fit="scale-down"
                      @click="openPdf(url)"
                    ></el-image>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <template slot="bottom" v-if="auditStatus">
      <el-button
        type="primary"
        @click="
          checkVisible = true
          auditType = 1
        "
      >
        审核通过
      </el-button>
      <el-button
        type="warning"
        @click="
          checkVisible = true
          auditType = 2
        "
      >
        驳回
      </el-button>
    </template>
    <DialogCheck
      @updateList="getData"
      @error="error"
      @back="backEvent"
      :auditType="auditType"
      :processInstanceId="processInstanceId"
      :taskId="taskId"
      v-if="checkVisible"
      :visible.sync="checkVisible"
    />
    <DialogRemark
      v-if="remarkVisible"
      :visible.sync="remarkVisible"
      :currentItem="processCode"
      @refreshRemark="listRemark"
    />
    <!--  //服务配件归还申请、 服务收费单、设备形态转换申请、领料申请 审核通过前判断数量能否审核 失败提示弹窗 -->
    <el-dialog
      :destroy-on-close="true"
      :close-on-click-modal="false"
      title="提示"
      :visible.sync="showError"
      append-to-body
      width="800px"
      v-loading="loading"
    >
      <div style="font-weight: bold; font-size: 16px; margin-bottom: 12px">
        {{
          type == 3
            ? '服务工程师货位无此存货数量，无法完成归还申请，请联系服务工程师确认。'
            : '产品在u8系统库存不足，无法完成申请。请先在u8系统补充产品库存。'
        }}
      </div>
      <el-table
        border
        :data="errorTableList"
        v-loading="loading"
        height="400px"
      >
        <el-table-column
          header-align="center"
          show-overflow-tooltip
          prop="msg"
          label="错误信息"
        />
      </el-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showError = false">关闭</el-button>
      </span>
    </el-dialog>
  </Page>
</template>

<script>
import AuditHeader from './components/AuditHeader.vue'
import DetailType0 from './components/DetailType0.vue'
import DetailType1 from './components/DetailType1.vue'
import DetailType3 from './components/DetailType3.vue'
import DetailType4 from './components/DetailType4.vue'
import DetailType5 from './components/DetailType5.vue'
import DetailType6 from './components/DetailType6.vue'
import DetailType7 from './components/DetailType7.vue'
import DetailType8 from './components/DetailType8.vue'
import DialogRemark from './components/DialogRemark.vue'
import DialogCheck from './components/DialogCheck.vue'
import { exportPdf, exportPdf2 } from '@/utils/htmlToPdf.js'
export default {
  components: {
    AuditHeader,
    DetailType0,
    DetailType1,
    DetailType3,
    DetailType4,
    DetailType5,
    DetailType6,
    DetailType7,
    DetailType8,
    DialogRemark,
    DialogCheck
  },
  data() {
    return {
      fromPage: '',
      auditable: false,
      checkVisible: false,
      remarkVisible: false,
      showReject: false,
      applyResult: {},
      type: '', //业务类型
      orderCode: '',
      processCode: '',
      orderResult: {},
      detailResults: [],
      // 0-待审批；1-审批中；2-已通过；3-未通过;4-已撤销
      nodeStatus: ['待审批', '审批中', '已通过', '未通过', '已撤销'],
      ccStatus: ['待抄送', '', '已抄送', '', ''],
      // 1-处理中；2-已撤销；3-已驳回；4-已结束；5-已退回；6-已挂起；10-系统异常
      userStatus: [
        '',
        '处理中',
        '已撤销',
        '已驳回',
        '已通过',
        '已退回',
        '已挂起',
        '系统异常'
      ],
      userType: ['', '申请', '审批', '抄送'],
      processInstanceIdInfo: {},
      auditStatus: false,
      processInstanceId: '',
      taskId: '',
      showError: false,
      errorTableList: [],
      remarks: [], // 备注列表
      exportLoading: false
    }
  },
  computed: {
    nickname() {
      let nickname = this.$store.state.userInfo?.nickname
      return nickname
    }
  },
  created() {
    this.type = Number(this.$route.query.type)
    this.fromPage = this.$route.query.from
    this.processInstanceId = this.$route.query.processInstanceId
    this.getData()
  },
  methods: {
    openPdf(url) {
      window.open(url)
    },
    exportPdfHtml() {
      this.$nextTick(() => {
        if (!this.orderResult.orderCode) {
          this.$message.warning('暂无数据，无法导出')
          return
        }
        const $pdfHtml = this.$refs.pdfHtml
        if ($pdfHtml) {
          this.exportLoading = true
          this.$nextTick(async () => {
            await this.$utils.wait(1000)
            let time = this.$utils.dateFormat(
              this.orderResult.applyTime || this.orderResult.createTime,
              'YYYY-mm-dd'
            )
            let typeName =
              this.$constant.applyType.find(a => a.value === this.type)
                ?.label || ''
            let fileName =
              (this.orderResult.proposerName || this.nickname) +
              typeName +
              '单' +
              time
            exportPdf2(fileName, $pdfHtml, () => {
              this.exportLoading = false
            })
          })
        } else {
          this.$message.warning('找不到目标元素，无法导出')
        }
      })
    },
    error(data) {
      this.showError = true
      this.errorTableList = data
    },
    async getData() {
      try {
        this.loading = true
        const { processInstanceId, processCode } = this.$route.query
        this.processCode = processCode
        let applyResult = await this.$api.apply.applyResult({ processCode })
        applyResult.statusStr =
          (applyResult.invalidating == 1 ? '作废' : '') +
          this.$constant.auditStatus.find(a => a.value == applyResult.status)
            ?.label
        this.applyResult = applyResult
        this.orderCode = applyResult.orderCode
        let res
        switch (this.type) {
          case 0:
            res = await this.$api.apply.faultRepairInfo({
              orderCode: this.orderCode
            })
            break
          case 11:
          case 1:
            res = await this.$api.apply.standByApplyInfo({
              orderCode: this.orderCode
            })
            break
          case 21:
          case 2:
            res = await this.$api.apply.partsApplyInfo({
              orderCode: this.orderCode
            })
            break
          case 3:
            res = await this.$api.apply.partsReturnInfo({
              orderCode: this.orderCode
            })
            break
          case 4:
            res = await this.$api.apply.sellOrderInfo({
              orderCode: this.orderCode
            })
            break
          case 5:
            res = await this.$api.apply.convertInfo({
              orderCode: this.orderCode
            })
            break
          case 6:
            res = await this.$api.apply.storageOrderInfo({
              orderCode: this.orderCode
            })
            break
          case 71:
          case 7:
            res = await this.$api.apply.pickingOrderInfo({
              orderCode: this.orderCode
            })
            break
          case 8:
            res = await this.$api.apply.invoiceOrderInfo({
              orderCode: this.orderCode
            })
            break
        }
        if (!res) {
          this.$message.warning('没有找到该类型的详情')
          return
        }
        try {
          const unreadNum = await this.$api.workOrder.readDataByOrderCode({
            orderCode: this.processCode
          })
          this.$utils.refreshData(unreadNum)
        } catch (e) {
          console.log(e)
        }
        this.orderResult = res.orderResult || res.sellOrderResult || {}
        this.detailResults = res.detailResults || []

        this.showReject =
          this.applyResult.status == 2 || this.applyResult.invalidating == 1

        if (processInstanceId) {
          //获取审核数据
          this.processInstanceIdInfo =
            await this.$api.flowable.listAssignedUsers({
              processInstanceId: processInstanceId
            })
          if (this.fromPage == 'myAudit' || this.fromPage == 'msgNotice') {
            const { list, total } = await this.$api.apply.getAudit({
              status: 0,
              processInstanceId: processInstanceId,
              pageNo: 1,
              pageSize: 1
            })
            this.auditStatus = total > 0
            if (this.auditStatus) {
              this.taskId = list[0].taskId
              this.showReject = false
            }
          } else {
            this.auditStatus = false
          }
        }
        // 查询备注
        await this.listRemark()
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async listRemark() {
      let remarks = await this.$api.apply.listRemark({
        orderCode: this.processCode
      })
      const _this = this
      this.remarks = remarks.map(item => {
        if (item.imgUrls) {
          let imgUrls = item.imgUrls
            .filter(url => !url.includes('.pdf'))
            .map(url => {
              return _this.interceptImg(url)
            })
          let pdfs = item.imgUrls
            .filter(url => url.includes('.pdf'))
            .map(url => {
              return _this.interceptImg(url)
            })
          return { ...item, imgUrls, pdfs }
        } else {
          return item
        }
      })
      console.log(this.remarks)
    },
    async handleExport() {
      switch (this.type) {
        case 0:
          await this.$api.apply.exportFaultRepairInfo({
            orderCode: this.orderCode
          })
          break
        case 1:
        case 11:
          await this.$api.apply.exportStandByApplyInfo({
            orderCode: this.orderCode
          })
          break
        case 2:
        case 21:
          await this.$api.apply.exportPartsApplyInfo({
            orderCode: this.orderCode
          })
          break
        case 3:
          await this.$api.apply.exportPartsReturnInfo({
            orderCode: this.orderCode
          })
          break
        case 4:
          await this.$api.apply.exportSellOrderInfo({
            orderCode: this.orderCode
          })
          break
        case 5:
          await this.$api.apply.exportConvertInfo({
            orderCode: this.orderCode
          })
          break
        case 6:
          await this.$api.apply.exportStorageOrderInfo({
            orderCode: this.orderCode
          })
          break
        case 7:
        case 71:
          await this.$api.apply.exportPickingOrderInfo({
            orderCode: this.orderCode
          })
          break
        case 8:
          await this.$api.apply.exportInvoiceOrderInfo({
            orderCode: this.orderCode
          })
          break
      }
    },
    downloadFile() {
      let url = (name = this.orderResult.attachmentUrl)
      if (this.orderResult.attachmentUrl.includes('?fileName=')) {
        url = this.orderResult.attachmentUrl.split('?fileName=')[0]
        name = this.orderResult.attachmentUrl.split('?fileName=')[1]
      }

      this.$utils.openDownload(url, name)
    },
    getNicknames(arr) {
      return (arr || []).map(a => a.nickname).join('、')
    },
    backEvent() {
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-timeline-item__content {
  div {
    margin-bottom: 10px;
  }
}
.remark {
  width: 100%;

  .title {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
  }
  .content {
    padding: 5px;
    margin: auto 10px;
    border: 1px solid #ddd;
    border-radius: 5px;

    .img {
      width: 100px;
      height: 100px;
      padding: 2px 5px 2px 0;
    }
  }
}
/deep/ .page-content {
  position: relative;
}
.exportBtn {
  position: absolute;
  right: 0;
  top: 12px;
  z-index: 1;
}
</style>
